
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  background-color: hz-color(background-default);
  padding: hz-spacing(xs) hz-spacing(xxs);
  width: 100%;

  @include hz-breakpoint-m-up {
    background-color: transparent;
    padding: 0 hz-spacing(xxxxs) hz-spacing(s) hz-spacing(xxxxs);
  }
}

.back {
  margin-right: hz-spacing(s);
}

.wide {
  display: none;
  @include hz-breakpoint-m-up {
    display: inline-block;
  }
}

.small {
  display: inline-block;
  margin-right: hz-spacing(xxxxs);
  @include hz-breakpoint-m-up {
    display: none !important;
  }
}
