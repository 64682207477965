
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import '../../../../../scss/layout';

.container {
  @include hz-layout(report);
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: hz-spacing(m);
  padding: hz-spacing(s);

  width: 100%;

  @include hz-breakpoint-m-up {
    border-top: hz-border(standard) hz-color(border-light);
    flex-direction: row;
    margin-top: 0;
    padding: hz-spacing(s) hz-spacing(m);
  }

  .itemIdText {
    @include hz-textStyle(body-regular);
    color: hz-color(text-secondary);
    flex-grow: 1;
    margin-bottom: hz-spacing(xs);

    @include hz-breakpoint-m-up {
      margin-bottom: 0;
    }
  }

  .flaggingText {
    @include hz-textStyle(body-regular);
  }
}
