
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';

.root {
  padding: hz-spacing(xs) 0 hz-spacing(s);
  width: 100%;

  @include hz-breakpoint-m-up {
    padding: hz-spacing(xs) 0 0;
  }
}

.toast {
  align-items: center;
  max-width: 100%;
}

.content {
  align-items: center;
  display: flex;
}

.description {
  width: 100%;
}

.loanBanner {
  padding: hz-spacing(s);
  width: 100%;

  @include hz-breakpoint-s-up {
    padding: 0 hz-spacing(s) hz-spacing(s);
  }

  @include hz-breakpoint-l-up {
    padding: 0 0 hz-spacing(s);
  }
}
