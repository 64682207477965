
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  @include hz-textStyle(body-regular);
  color: hz-color(text-secondary);
  flex: 0 0 100%;
  margin: hz-spacing(xxs) 0 hz-spacing(xs) 0;
  padding: 0 hz-spacing(s) hz-spacing(xxxxs);

  @include hz-breakpoint-m-up {
    order: -1;
    padding: 0;
  }

  &.withDivider {
    @include hz-breakpoint-m-up {
      border-bottom: hz-border(standard) hz-color(border-light);
      margin-bottom: 0;
      order: -2;
      padding-bottom: hz-spacing(xxs);
    }
  }
}

.stat {
  display: inline-block;
  margin-right: hz-spacing(m);

  &:last-child {
    margin-right: 0;
  }
}

.summary {
  margin-left: hz-spacing(xxxs);
}

.summaryDate {
  @include hz-breakpoint-m-up {
    display: none;
  }
}

.dateExtra {
  display: none;

  @include hz-breakpoint-m-up {
    display: inline;
  }
}
