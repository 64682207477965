
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(characteristics);

  margin-top: hz-spacing(m);
  padding: hz-spacing(s);
  width: 100%;

  @include hz-breakpoint-m-up {
    margin-top: 0;
    padding: hz-spacing(m) hz-spacing(m) hz-spacing(s);
  }
}

.title {
  @include hz-textStyle(body-large-strong);
  margin-bottom: hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    margin-bottom: hz-spacing(xs);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.oneColumn {
  @include hz-breakpoint-m-up {
    flex-direction: column;

    .item {
      width: 50%;

      &:nth-child(2) {
        border-top: 0;
      }
    }
  }
}

.item {
  border-bottom: hz-border(standard) hz-color(border-light);
  display: flex;
  flex: 0 100%;
  padding-bottom: hz-spacing(xxs);
  padding-top: hz-spacing(xxs);
  min-width: 0;

  @include hz-breakpoint-m-up {
    flex: 0 50%;

    &:nth-child(odd) {
      flex: 0 calc(50% - #{hz-spacing(xs)});
      margin-right: hz-spacing(xs);
    }

    &:nth-child(2) {
      border-top: hz-border(standard) hz-color(border-light);
    }
  }

  &:first-child {
    border-top: hz-border(standard) hz-color(border-light);
  }
}

.itemMultiSelect {
  @include hz-breakpoint-m-up {
    margin-right: calc(50% + #{hz-spacing(xs)});
  }
}

.label {
  flex-grow: 1;
  margin-right: hz-spacing(xxs);
  width: 50%;
}

.value {
  flex-grow: 1;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.valueOptions {
  margin: 0;
  padding-left: hz-spacing(s);
}
