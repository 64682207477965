
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;

  @include hz-breakpoint-m-up {
    display: inline-block;
    margin-top: hz-spacing(xxxs);

    &.horizontal {
      display: flex;
      justify-content: normal;
      overflow: hidden;
    }
  }
}

.block {
  @include hz-breakpoint-m-up {
    background-color: hz-color(background-default);
    min-width: 112px;
    padding: hz-spacing(xxs) 0;
    text-align: left;

    + .block {
      border-top: hz-border(standard) hz-color(border-light);
    }
  }
}

.horizontal .block {
  @include hz-breakpoint-m-up {
    min-width: 0;

    &:last-child {
      display: none;
    }

    + .block {
      border: 0;
      margin-left: hz-spacing(l);
    }
  }

  @include hz-breakpoint-l-up {
    &:last-child {
      display: block;
    }
  }
}

.blockLink {
  text-align: left;
  vertical-align: top;
  @include hz-breakpoint-m-up {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.title {
  @include hz-breakpoint-m-up {
    @include hz-textStyle(body-small);
    color: hz-color(text-secondary);
  }
}

.value {
  @include hz-breakpoint-m-up {
    @include hz-textStyle(body-large-strong);

    .horizontal & {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.energyLabel {
  @include hz-breakpoint-m-up {
    display: inline-block;
    line-height: 0;
    margin-top: hz-spacing(xxxs);
  }
}

.warrantyImage {
  @include hz-breakpoint-m-up {
    margin-top: hz-spacing(xxxs);
  }
}

.info {
  align-items: center;
  display: flex;
}
