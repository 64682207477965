
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;

  @include hz-breakpoint-m-up {
    align-items: center;
    display: flex;
    height: 114px;
    justify-content: center;
    margin: 0 auto hz-spacing(m);
    width: 100%;
  }
}
