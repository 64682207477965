
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'src/client/scss/layout';

.root {
  @include hz-layout(bannerRight);
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 250px;
  padding-top: hz-spacing(m);
  width: 100%;
}
