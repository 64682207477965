
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  align-items: center;
  background: hz-color(background-light);
  border: hz-border(standard) hz-color(border-light);
  color: hz-color(text-secondary);
  cursor: auto;
  display: flex;
  flex-direction: column;
  height: 250px;
  justify-content: center;
  padding: 0 hz-spacing(s);

  @include hz-breakpoint-m-up {
    height: 300px;
  }
}

.title {
  @include hz-textStyle(body-large);
  padding: hz-spacing(xxs) 0 hz-spacing(xxxxs);
}

.description {
  @include hz-textStyle(body-regular);
}
