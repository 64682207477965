
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'node_modules/@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(characteristics);

  border-bottom: hz-border(standard) hz-color(border-light);

  margin-top: hz-spacing(m);
  padding: hz-spacing(s);
  width: 100%;

  @include hz-breakpoint-m-up {
    margin-top: 0;
    padding: hz-spacing(s) hz-spacing(m) 0;
  }

  &[data-active='true'] {
    [data-tab='overview'] {
      display: block;
    }

    [data-tab='characteristics'] {
      display: none;
    }
  }

  &[data-active='false'] {
    [data-tab='overview'] {
      display: none;
    }

    [data-tab='characteristics'] {
      display: block;
    }
  }
}

.title {
  @include hz-textStyle(body-large-strong);
  margin-bottom: hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    margin-bottom: hz-spacing(xs);
  }
}

.enrichedTitle {
  margin-bottom: hz-spacing(xxxs);
  margin-top: hz-spacing(xs);

  @include hz-breakpoint-m-up {
    margin-bottom: hz-spacing(xxs);
    margin-top: hz-spacing(s);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
}

[data-tab='characteristics'] {
  .oneColumn {
    @include hz-breakpoint-m-up {
      flex-direction: column;

      .item {
        width: 100%;
      }
    }
  }
}

.section {
  padding-bottom: hz-spacing(xxs);

  &:last-child {
    padding-bottom: 0;
  }
}

.oneColumn {
  @include hz-breakpoint-m-up {
    flex-direction: column;

    .item {
      width: 50%;

      &:nth-child(odd) {
        flex: 1;
        margin-right: 0;
      }
    }
  }
}

.item {
  border-bottom: hz-border(standard) hz-color(border-light);
  display: flex;
  flex: 0 100%;
  padding-bottom: hz-spacing(xxs);
  padding-top: hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    flex: 0 50%;

    &:nth-child(odd) {
      flex: 0 calc(50% - #{hz-spacing(xs)});
      margin-right: hz-spacing(xs);
    }
  }
}

.itemMultiSelect {
  @include hz-breakpoint-m-up {
    margin-right: calc(50% + #{hz-spacing(xs)});
  }
}

.label {
  flex-grow: 1;
  margin-right: hz-spacing(xxs);
  width: 50%;
}

.value {
  flex-grow: 1;
  width: 50%;
}

.valueOptions {
  margin: 0;
  padding-left: hz-spacing(s);
}

.itemWithIconWrapper {
  display: flex;
  flex: 0 50%;
  max-width: 50%;
  padding: hz-spacing(xs) 0;

  @include hz-breakpoint-m-up {
    flex: 0 33.3%;
    max-width: 33.3%;
  }

  .itemMultiSelect {
    @include hz-breakpoint-m-up {
      margin-right: hz-spacing(xxs);

      .value {
        width: 100%;
      }
    }
  }

  .label {
    @include hz-textStyle(body-regular);
    color: hz-color(text-secondary);
    margin-bottom: hz-spacing(xxxs);
    width: 100%;
  }

  .value {
    @include hz-textStyle(body-large-strong);
  }

  .icon {
    margin: hz-spacing(xxxs) hz-spacing(xxs) 0 0;
  }
}

.itemWithIconWrapper-options {
  flex: 0 100%;

  &:nth-child(odd) {
    flex: 0 100%;
  }
}

.itemWithIconWrapper-brand {
  flex: 0 100%;
  max-width: 100%;

  .value {
    width: 100%;
  }
}

.itemWithIcon-icon {
  margin-right: hz-spacing(xxs);
}

.itemWithIcon {
  min-width: 0;

  .value {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;

    @include hz-breakpoint-m-up {
      white-space: normal;
    }
  }
}

:global(.hz-SvgAsset.hz-SvgAssetLogoNap.hz-Asset--fluid) {
  height: 16px;
  width: 40px;
}

:global(.hz-SvgAsset.hz-SvgAssetLogoCarPass.hz-Asset--fluid) {
  height: 16px;
  width: 70px;
}

:global(.CarAttributesAccordion-root) {
  margin-top: hz-spacing(xxs);
}

:global(.CarAttributesAccordion-header) {
  padding: hz-spacing(s) 0;
}

:global(.CarAttributesAccordion-headerIcon) {
  margin-right: hz-spacing(xxs);
}

:global(.CarAttributesAccordion-headerLabel) {
  @include hz-textStyle(body-large-strong);
}

:global(.CarAttributesAccordion-body) {
  margin: hz-spacing(xxs) 0;
}

:global(.CarAttributesAccordion-bodyItems) {
  @include hz-textStyle(body-regular);
  display: flex;
  margin-bottom: hz-spacing(s);
}

:global(.CarAttributesAccordion-valuesList) {
  display: inline-block;
}

:global(.CarAttributesAccordion-bodyItemLabel) {
  color: hz-color(text-secondary);
  flex: 1;
}

:global(.CarAttributesAccordion-bodyItemValue) {
  @include hz-textStyle(body-regular-strong);
  color: hz-color(text-primary);
  flex: 0 1 50%;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global(.hz-AccordionHeader-expandToggleIcon.hz-Icon--m) {
  height: hz-spacing(l);
  width: hz-spacing(l);
}

:global(.hz-PopOverContent) {
  z-index: 1;
}

:global(.hz-PopOverContent--bottom .hz-PopOverContent-arrow) {
  top: -17px;
}

:global(.CarAttributes-value .hz-Asset) {
  margin-left: hz-spacing(xxs);
}
