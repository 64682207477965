
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(description);

  margin-top: hz-spacing(m);
  padding: hz-spacing(s) hz-spacing(s) hz-spacing(xs);
  width: 100%;

  @include hz-breakpoint-m-up {
    margin-top: 0;
    padding: hz-spacing(s) hz-spacing(m) hz-spacing(m);
  }
}

.description {
  word-break: break-word;
}

[data-collapsed='true'] {
  .description {
    max-height: 180px;
    overflow: hidden;
    transition: max-height 1s ease-in-out;

    @include hz-breakpoint-m-up {
      max-height: initial;
    }
  }
}

.title {
  @include hz-textStyle(body-large-strong);
  margin-bottom: hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    margin-bottom: hz-spacing(xs);
  }
}

.personalLeaseTable {
  margin-top: hz-spacing(xs);

  .container {
    overflow-x: scroll;
    mask-image: linear-gradient(to right, hz-color(background-default) 96%, transparent 100%);

    @include hz-breakpoint-l-up {
      mask-image: none;
    }
  }

  .table {
    display: table;
    border: hz-border(standard) hz-color(border-light);
    border-collapse: collapse;
    margin: 0 hz-spacing(s) hz-spacing(xxs) 0;

    @include hz-breakpoint-l-up {
      margin-right: 0;
    }
  }

  .row {
    display: table-row;

    + .row {
      border-top: hz-border(standard) hz-color(border-light);
    }
  }

  .cell {
    display: table-cell;
    padding: hz-spacing(xxxs);

    + .cell {
      border-left: hz-border(standard) hz-color(border-light);
    }
  }
}
