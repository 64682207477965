
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import "~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints";

.root {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: hz-spacing(xs);

  @include hz-breakpoint-m-up {
    margin-top: hz-spacing(xxs);
  }
}

.deliveryLabel {
  @include hz-textStyle(body-regular);
  color: hz-color(text-primary);
  margin-right: hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    margin-top: 0;
  }
}

.shipping {
  display: inline-block;
  
  @include hz-breakpoint-m-up {
    margin-top: hz-spacing(xs);
    display: block;
  }
}

.logoAndInfo {
  align-items: center;
  display: flex;
  margin-top: hz-spacing(xs) !important;
  text-align: left;
  
  @include hz-breakpoint-m-up {
    width: 100%;
  }
}

.brenger {
  display: none;
  @include hz-breakpoint-m-up {
    display: flex;
  }
}

.baseLine {
  align-items: baseline;
}

.flexStart {
  align-items: flex-start;
}

.logo {
  width: 24px;
  height: 24px;
}

.info {
  margin-left: hz-spacing(xxxs);
}
