
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: hz-spacing(xs);
  margin-bottom: hz-spacing(xs);

  @include hz-breakpoint-m-up {
    margin-top: hz-spacing(xxs);
  }
}

.benefit {
  align-items: center;
  display: flex;
  text-align: left;

  @include hz-breakpoint-m-up {
    width: 100%;
  }

  &:not(:first-child) {
    margin-top: hz-spacing(xxs);
  }
}

.icon{
  margin-top: 0;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  background-color: hz-color(signal-success-default--inverse);
  border-radius: hz-spacing(xxxs);
  display: flex;
  align-items: center;
}

.label {
  @include hz-textStyle(body-regular-strong);
  margin-left: hz-spacing(xxxs);
}
