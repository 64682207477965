
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import './layout';

// Global styles
html,
body {
  @include hz-textStyle(body-regular);
  color: hz-color(text-primary);
}

// Global styles
.hz-Page {
  background: hz-color(background-light);

  &-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // make sure the page is never wider than the screen
    // to prevent horizontal scrollbar
    width: 100%;
  }

  &-content {
    @include hz-breakpoint-s-up {
      padding-top: hz-spacing(s);

      .hz-Page-element--full-width,
      .hz-Page-element--main,
      .hz-Page-element--aside {
        padding: 0 hz-spacing(s);
      }
    }

    @include hz-breakpoint-m-up {
      .hz-Page-element--main {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
      }

      .hz-Page-element--aside {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }

    @include hz-breakpoint-l-up {
      .hz-Page-element--full-width {
        padding: 0;
      }

      .hz-Page-element--main {
        padding: 0;
      }

      .hz-Page-element--aside {
        padding: 0 0 0 hz-spacing(s);
      }
    }
  }

  &-columnGridLayout {
    width: 100%;

    @include hz-breakpoint-xl-up {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &-columnLayout {
    width: 100%;
  }
}

.white-background {
  background: hz-color(background-default);
}

.block-wrapper {
  background: hz-color(background-default);
  box-shadow: hz-shadow(low);

  @include hz-breakpoint-m-up {
    border-radius: hz-radius(standard);
  }
}

.block-wrapper-s {
  background: hz-color(background-default);
  box-shadow: hz-shadow(low);

  @include hz-breakpoint-m-up {
    background: none;
    border-radius: 0;
    box-shadow: unset;
  }

  &--hide-top {
    &::before {
      background: hz-color(background-default);
      content: '';
      height: hz-spacing(xxxxs);
      left: 0;
      position: absolute;
      top: -#{hz-spacing(xxxxs)};
      width: 100%;

      @include hz-breakpoint-m-up {
        content: none;
      }
    }
  }
}

.block-wrapper-m {
  overflow: hidden;

  @include hz-breakpoint-m-up {
    background: hz-color(background-default);
    border-radius: hz-radius(standard);
    box-shadow: hz-shadow(low);
  }
}

.display-block-s {
  display: contents;

  @include hz-breakpoint-m-up {
    display: none;
  }
}

.display-block-m {
  display: contents;

  @include hz-breakpoint-m-up {
    display: block;
  }
}

.outside-container-m {
  display: none;

  @include hz-breakpoint-m-up {
    display: block;
    width: 100%;
  }
}

.outside-top-container {
  width: 100%;

  @include hz-breakpoint-m-up {
    min-height: 106px;
    padding: hz-spacing(s) 0 0;
  }
}

.outside-vertical-container-xl {
  display: none;

  @include hz-breakpoint-xl-up {
    display: block;
    flex: 1;
    min-width: 1px;
    padding-top: hz-spacing(xxl);
  }
}

a {
  color: hz-color('signal-link-default');

  &:hover {
    color: hz-color('signal-link-hover');
    text-decoration: none;
  }

  &:active {
    color: hz-color('signal-link-active');
  }
}

.hz-FooterAlternative {
  background-color: hz-color(background-default);
  border: 0;
}

.right-banner-root-container {
  min-height: 250px;
}

// We lazy load this component but we still want it to load into the correct place to avoid CLS
.SellerOtherItems-root {
  @include hz-layout(sellerOwnAds);
}
