
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(adsense);

  overflow: hidden;
  position: relative;
  width: 100%;
}

.loaded {
  margin-top: hz-spacing(m);
}

.title {
  border-bottom: hz-border(standard) hz-color(border-light);
  color: hz-color(text-secondary);
  padding: hz-spacing(s) hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    padding: hz-spacing(m) hz-spacing(xxs);
  }
}
