
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  background: hz-color(background-light);
  border-bottom: hz-border(standard) hz-color(border-light);
  border-top: hz-border(standard) hz-color(border-light);
  display: flex;
  height: 272px;
  justify-content: center;
  overflow: hidden;

  @include hz-breakpoint-m-up {
    border: hz-border(standard) hz-color(border-light);
    height: 302px;
    overflow: hidden;
  }

  &:hover {
    cursor: pointer;
  }
}

.image {
  max-height: 270px;
  object-fit: contain;
  width: 100%;

  @include hz-breakpoint-m-up {
    max-height: 300px;
  }
}
