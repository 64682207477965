
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;

  @include hz-breakpoint-m-up {
    display: flex;
  }
}

.box {
  margin: hz-spacing(s) 0 0 hz-spacing(xs);
}

// we have to keep old styling for this banners

.container {
  background: hz-color(signal-action-feedback--inverse);
  background: linear-gradient(to bottom, hz-color(signal-action-feedback--inverse) 0%, hz-color(background-default) 50%, hz-color(background-default) 100%);
  border: solid 1px hz-color(border-light);
  display: flex;
  height: 103px;
  margin-left: hz-spacing(xs);
  margin-top: hz-spacing(s);
  padding: 15px;
  width: 300px;
}

:global(.premium-content-left) {
  text-align: left;
}

:global(.premium-content-right) {
  text-align: right;
}

:global(.premium-content-left .first-line),
:global(.premium-content-right .first-line) {
  @include hz-textStyle--bodyRegularStrong;
  font-size: 13px;
}

:global(.premium-content-left .second-line),
:global(.premium-content-right .second-line) {
  @include hz-textStyle--bodySmall;
  font-size: 11px;
}

:global(.premium-content-logo) {
  margin-bottom: hz-spacing(s);
}
