
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';

.root {
  margin: hz-spacing(xs) hz-spacing(s) hz-spacing(s);
  max-width: 100%;

  @include hz-breakpoint-m-up {
    margin: 0 0 hz-spacing(xs);
  }
}
