
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  padding: 0 hz-spacing(xxs);
  position: absolute;
  right: hz-spacing(s);

  @include hz-breakpoint-m-up {
    padding: 0 hz-spacing(s);
    right: 0;
  }
}

.text {
  display: none;
  margin-left: hz-spacing(xxxs);

  @include hz-breakpoint-m-up {
    display: inline;
  }
}
