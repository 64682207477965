
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(sellerInfo);

  margin-top: hz-spacing(m);
  padding: 0;
  width: 100%;

  @include hz-breakpoint-m-up {
    margin-top: 0;
  }
}

.section {
  @include hz-textStyle(body-regular);
  padding: hz-spacing(s);

  &:not(:last-child) {
    border-bottom: hz-border(standard) hz-color(border-light);
  }
}

.row {
  color: hz-color(text-secondary);
  margin-bottom: hz-spacing(xxs);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowWithIcon {
  align-items: center;
  color: hz-color(text-secondary);
  display: flex;
  margin-bottom: hz-spacing(xxs);

  &:last-child {
    margin-bottom: 0;
  }
}

.rowTitle {
  margin-bottom: hz-spacing(xs);

  @include hz-breakpoint-m-up {
    margin-bottom: hz-spacing(s);
  }
}

.name {
  @include hz-textStyle(body-large);
  display: block;
  margin-right: hz-spacing(xxl);
}

.icon {
  display: inline-block;
  margin-right: hz-spacing(xxs);
}

.websiteLink {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profilePictures {
  background: hz-color(background-light);
  height: 134px;
  position: relative;
}

.backgroundPicture {
  background: no-repeat center;
  height: 100%;
  width: 100%;
}

.logoPicture {
  background-color: hz-color(background-default);
  border: hz-border(standard) hz-color(background-default);
  border-radius: hz-radius(standard);
  bottom: hz-spacing(s);
  box-shadow: hz-shadow(low);
  height: 70px;
  left: hz-spacing(s);
  position: absolute;
  width: 70px;

  img {
    border-radius: hz-radius(standard);
    height: 100%;
    object-fit: contain;
    width: 100%;
    background-size: 10000px;
    background-position: 10px 10px;
  }
}

.representativeList {
  padding: 0;
}

.externalReviewsHeading {
  display: flex;
  padding-bottom: hz-spacing(m);
}

.externalReviewsHeadingName {
  margin-bottom: hz-spacing(xs);
}

.externalReviewsHeadingRatingText {
  display: block;

  @include hz-breakpoint-m-up {
    display: inline;
  }
}

.externalReviewsHeadingImage {
  margin-right: hz-spacing(m);
  max-height: 110px;
  max-width: 110px;
}

.externalReviewHeadingMeta {
  color: hz-color(text-tertiary);
  margin-top: hz-spacing(xs);
}

.externalReview {
  border-top: hz-border(standard) hz-color(border-light);
  padding: hz-spacing(m) 0;
  position: relative;
}

.externalReviewText {
  margin-bottom: hz-spacing(s);
}

.externalReviewerName {
  @include hz-textStyle(body-large-strong);
  display: inline-block;
  margin-bottom: hz-spacing(xxxs);
}

.externalReviewPlace {
  margin-left: hz-spacing(xxs);
}

.externalReviewMetaInfo {
  color: hz-color(text-tertiary);
  margin-bottom: hz-spacing(xs);
}

.externalReviewProvider {
  padding: hz-spacing(s) 0;
}
