$hz-order: (
  'breadcrumb': 0,
  'admarktsimilaritemstop': 0,
  'listingInfo': 4,
  'sellerInfoCTA': 8,
  'bids': 12,
  'bannerMid': 14,
  'inspectionService': 27,
  'share': 16,
  'description': 20,
  'verifiedRefurbishedInfo': 21,
  'highlights': 22,
  'characteristics': 24,
  'sustainability': 25,
  'adsense': 26,
  'sellerInfo': 28,
  'report': 41,
  'bannerRight': 32,
  'feed': 33,
  'admarktsimilaritems': 37,
  'sellerOwnAds': 36,
  'otherAds': 40,
  'popularSearches': 42,
  'footerCategories': 44
);

@function hz-layout($order) {
  @if map_has_key($hz-order, $order) {
    @return map-get($hz-order, $order);
  }
  @error '#{$order} is not valid for $hz-order';
}

@mixin hz-layout($order) {
  order: hz-layout(#{$order});
}
