
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(verifiedRefurbishedInfo);
  padding: hz-spacing(m) hz-spacing(s);
  width: 100%;

  @include hz-breakpoint-m-up {
    padding: hz-spacing(s) hz-spacing(m);
  }
}

.wrapper {
  background-color: hz-color(signal-success-default--inverse);
  padding: hz-spacing(s);
  border-radius: hz-spacing(xxxs);

  @include hz-breakpoint-m-up {
    padding: hz-spacing(m);
  }
}

.verifiedBadge {
  display: flex;
  align-items: center;
  border-radius: hz-spacing(xxxs);
  margin-bottom: hz-spacing(xxs);
  background-color: hz-color(signal-success-hover);
  padding: hz-spacing(xxxs) hz-spacing(xxs);
  width: fit-content;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: hz-spacing(xxxs);
}
