
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  float: right;
  padding: 0 hz-spacing(xxs);
}
