
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.button {
    margin-bottom: hz-spacing(xs);
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &.link:hover {
      text-decoration: none;
    }
}

.sellerCta {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


