
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: none;

  @include hz-breakpoint-m-up {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: hz-spacing(xs);
  }

  button:first-child {
    margin-right: hz-spacing(xxs);
  }

  button:last-child {
    margin-left: hz-spacing(xxs);
  }
}

.cover {
  display: flex;
  flex: 1;
  overflow-x: hidden;
  position: relative;
}

.scroll {
  transition: transform 300ms ease-in-out;
  white-space: nowrap;
}

.item {
  background-repeat: no-repeat;
  background-size: cover;
  border: hz-border(large);
  border-color: transparent;
  cursor: pointer;
  display: inline-block;
  height: 65px;
  margin-right: hz-spacing(xxs);
  position: relative;
  width: 65px;

  &.active {
    border: hz-border(large);
    border-color: hz-color(signal-action-default);
    transition: border-color 300ms ease-in-out;
  }
}
