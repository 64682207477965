.EnergyLabel {
  &.hz-Asset.hz-SvgAsset {
    height: 26px;
    width: 85px;
  }
}

.CarUspBlocks-energyLabel .EnergyLabel {
  &.hz-Asset.hz-SvgAsset {
    height: 12px;
    width: 39px;
  }
}
