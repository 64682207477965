
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  display: flex;

  @include hz-breakpoint-xl-up {
    position: sticky;
    top: 135px;
  }
}

.left {
  justify-content: flex-end;
  padding-right: hz-spacing(xs);
}

.right {
  padding-left: hz-spacing(xs);
}
