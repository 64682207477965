
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(share);

  @include hz-textStyle(body-large);
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  margin-top: hz-spacing(m);
  padding: hz-spacing(xs) hz-spacing(s) hz-spacing(xs);

  @include hz-breakpoint-m-up {
    border-bottom: hz-border(standard) hz-color(border-light);
    margin-top: 0;
    padding: hz-spacing(s) hz-spacing(m) hz-spacing(s);
  }

  :global(.hz-Link) {
    margin-right: hz-spacing(xs);
  }
}

.with {
  @include hz-textStyle(body-large-strong);
  flex: 1;
  margin-right: hz-spacing(xs);

  @include hz-breakpoint-m-up {
    flex: unset;
  }
}

.copyLink {
  @include hz-textStyle(body-regular);
  padding-top: hz-spacing(xxxs);
}

.other {
  display: block;

  @include hz-breakpoint-m-up {
    display: none;
  }
}

.hideOnMobile {
  display: none;
  margin-right: hz-spacing(xs);

  @include hz-breakpoint-m-up {
    display: block;
  }
}
