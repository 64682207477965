
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '../../../../../scss/layout';

.root {
  @include hz-layout(listingInfo);

  background: hz-color(background-default);
  padding: 0;
  width: 100%;

  @include hz-breakpoint-m-up {
    background: none;
    border-bottom: hz-border(standard) hz-color(border-light);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: hz-spacing(s) hz-spacing(m);
  }
}

// because favourite button has `position: absolute` extra padding is needed for listing header
// to make sure that listing title is not hovered by the button
$favourite-button-width-mobile: hz-spacing(xxl) + hz-spacing(xs);
$favourite-button-width-desktop: hz-spacing(xxl) + hz-spacing(xxxl);

.header {
  flex: 0 0 100%;
  padding: hz-spacing(xs) $favourite-button-width-mobile hz-spacing(xxxxs) hz-spacing(s);
  position: relative;

  @include hz-breakpoint-m-up {
    order: -1;
    padding: 0 $favourite-button-width-desktop 0 0;
  }
}

.title {
  @include hz-textStyle--title4;
  word-break: break-word;
}

.divider {
  margin-left: 0;
  margin-right: 0;
}

.informationContainer {
  flex: 0 0 100%;
  padding: 0 hz-spacing(s);

  @include hz-breakpoint-m-up {
    flex: 1;
    padding: 0 0 0 hz-spacing(m);
  }
}

.price {
  @include hz-textStyle--title3;
}

.pricePerMonth {
  @include hz-textStyle--bodyLarge;
}

.showLargePhotos {
  @include hz-breakpoint-m-up {
    .header {
      min-height: 56px;
      order: -2;
    }

    .informationContainer {
      margin-bottom: hz-spacing(s);
      order: -1;
      padding: 0;
      position: relative;
      width: 100%;
    }

    .price {
      position: absolute;
      right: 0;
      top: -40px;
    }
  }
}
