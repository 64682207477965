
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(popularSearches);
  margin-top: hz-spacing(m);
  padding: hz-spacing(s) hz-spacing(s) hz-spacing(m) hz-spacing(s);

  @include hz-breakpoint-m-up {
    padding: hz-spacing(s) hz-spacing(m) hz-spacing(m) hz-spacing(m);
  }
}

.title {
  @include hz-textStyle--bodyLargeStrong;
  margin-bottom: hz-spacing(xxxs);
}

.list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-top: hz-spacing(xxs);
  width: 50%;
  @include hz-breakpoint-m-up {
    width: 25%;
  }
}
