
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(highlights);
  border-bottom: hz-border(standard) hz-color(border-light);
  margin-top: hz-spacing(m);
  padding: hz-spacing(s);
  width: 100%;

  @include hz-breakpoint-m-up {
    margin-top: 0;
    padding: hz-spacing(s) hz-spacing(m);
  }
}

.title {
  @include hz-textStyle(body-large-strong);
  margin-bottom: hz-spacing(xxs);
}

.highlightsList {
  @include hz-breakpoint-m-up {
    display: flex;
    flex-wrap: wrap;
  }
}

.highlight {
  margin-top: hz-spacing(xxs);

  @include hz-breakpoint-m-up {
    width: 50%;
  }
}

.icon {
  margin-right: hz-spacing(xxs);
}
