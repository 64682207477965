
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  padding: hz-spacing(xxs) 0 0;
  position: relative;

  @include hz-breakpoint-m-up {
    display: none;
  }
}

.isCollapsed {
  padding-top: hz-spacing(xxxs);

  &::before {
    background: linear-gradient(180deg, hz-color(background-default) 0%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: 24px;
    position: absolute;
    top: -24px;
    transform: rotate(-180deg);
    width: 100%;
  }
}
