
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(inspectionService);
  position: relative;
  padding: hz-spacing(s);
  margin-top: hz-spacing(m);

  @include hz-breakpoint-m-up {
    align-items: center;
    border-bottom: hz-border(standard) hz-color(border-light);
    flex-direction: row;
    margin-top: hz-spacing(m);
    padding: hz-spacing(s) hz-spacing(m) hz-spacing(s);
  }
}

.content {
  @include hz-breakpoint-m-up {
    margin-right: 90px;
  }
}

.title {
  @include hz-textStyle--bodyLargeStrong;
  margin-bottom: hz-spacing(xs);
  margin-right: 115px;
}

.usp {
  @include hz-textStyle--bodyRegular;
  margin: 0 hz-spacing(s) hz-spacing(xxs) 0;
  display: block;

  @include hz-breakpoint-m-up {
    display: inline-block;
  }
}

.image {
  position: absolute;
  top: hz-spacing(xxs);
  right: 0;
  width: 115px;

  @include hz-breakpoint-m-up {
    top: hz-spacing(m);
  }

  @include hz-breakpoint-l-up {
    width: 170px;
  }
}

.icon {
  margin-right: hz-spacing(xxxs);
}

.promo {
  margin-bottom: hz-spacing(s);
  color: hz-color(text-secondary);
}

.button {
  width: 100%;
  max-width: 420px;

  @include hz-breakpoint-s-up {
    width: auto;
  }
}
