
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@mixin identity-info {
  background-color: hz-color(background-light);
  border-radius: hz-radius(standard);
  color: hz-color(text-secondary);
}

.message {
  @include identity-info;
  padding: hz-spacing(xs);
}

.badge {
  @include identity-info;
  display: inline-block;
  padding: hz-spacing(xxxs) hz-spacing(xxs);
}
