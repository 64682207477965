
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  margin-top: hz-spacing(xxxxs);

  &:global(.hz-Asset.hz-SvgAsset) {
    background-repeat: no-repeat;
    height: 16px;
  }
}

.logoNap {
  &:global(.hz-Asset.hz-SvgAsset) {
    width: 40px;
  }
}

.logoNapHover {
  &:hover {
    cursor: pointer;
  }
}

.logoCarPass {
  &:global(.hz-Asset.hz-SvgAsset) {
    width: 71px;
  }

  &:hover {
    cursor: pointer;
  }
}
