
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/BoxShadow';
@import 'src/client/scss/layout';

.root {
  @include hz-layout(sellerInfoCTA);
  padding: hz-spacing(xxs) hz-spacing(s) hz-spacing(m);
  position: relative;
  width: 100%;

  @include hz-breakpoint-m-up {
    border-top: hz-border(standard) hz-color(border-light);
    padding: hz-spacing(s);
  }
}

.button {
  margin-bottom: hz-spacing(xs);
  width: 100%;

  // overrides isolated link style because this element looks like a button
  &.link:hover {
    text-decoration: none;
  }
}

.stickyContainer {
  width: 100%;
  @include hz-breakpoint-m-up {
   display: none;
  }
}

.stickyContainerFixed {
  position: fixed;
    padding: hz-spacing(s) hz-spacing(s) hz-spacing(s);
    border-top:hz-border(standard) hz-color(border-light);
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: hz-color(background-default);

    > button {
      margin-bottom: 0;
    }
    @include hz-breakpoint-m-up {
     display: none;
    }
}