
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  background-color: hz-color(background-light);
  border-top: hz-border(standard) hz-color(border-light);
  bottom: 0;
  left: 0;
  min-height: 67px;
  min-width: 100%;
  padding: hz-spacing(xxs) 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: hz-zIndex(header-sticky);

  @include hz-breakpoint-m-up {
    background-color: transparent;
    border-top: 0;
    min-height: 90px;
    padding: 0;
    position: static;
  }
}

.buttonClose {
  position: absolute;
  right: 0;
  top: -#{hz-spacing(xl) - hz-spacing(xxxxs)};

  @include hz-breakpoint-m-up {
    display: none;
  }

  // the styles which we need to override have higher priority .hz-Button.hz-Button--tertiary
  // this is why we use global here
  &:global(.hz-Button) {
    background-color: hz-color(background-light);
    border-color: hz-color(border-light);
    border-bottom-color: hz-color(background-light);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.rootExperiment {
  display: none;
  @include hz-breakpoint-m-up {
    display: flex;
    justify-content: center;
  }
}
