
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';
@import 'src/client/scss/layout';

/* hide scrollbar but keep scrolling functionality */
/* @see https://stackoverflow.com/a/49278385/1387163 */
@mixin invisible-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
  }
}

.root {
  @include hz-layout(admarktsimilaritems);
  background-color: hz-color(background-default);
  margin-top: hz-spacing(m);
  padding: hz-spacing(s);
  width: 100%;

  .headerTitle {
    margin-bottom: hz-spacing(s);
  }
}

.rootHorizontal {
  @include hz-layout(admarktsimilaritemstop);
  padding: hz-spacing(s) hz-spacing(s) hz-spacing(m);

  @include hz-breakpoint-m-up {
    margin-bottom: hz-spacing(m);
    padding: hz-spacing(m);
  }

  .headerTitle {
    margin-bottom: hz-spacing(s);
  }
}

.headerTitle {
  @include hz-textStyle(body-large-strong);
}

.list {
  display: block;
}

.listHorizontal {
  @include invisible-scroll-bar;
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  margin: -#{hz-spacing(xxs) - hz-spacing(xxxxs)};
  overflow-x: scroll;

  @include hz-breakpoint-m-up {
    margin: -(hz-spacing(xxs));
  }

  .item {
    flex: 0 20%;
    margin: #{hz-spacing(xxs) - hz-spacing(xxxxs)};
    min-width: 187px;

    @include hz-breakpoint-m-up {
      margin: hz-spacing(xxs);
      min-width: 175px;
    }

    &:last-child {
      /* prevent right shadow to be cut off by overflow-x of the .list */
      /* @see https://stackoverflow.com/questions/12713492/box-shadow-being-cut-off */
      padding-right: 1px;
    }
  }
}

.similarItemsExperiment {
  display: flex;
  flex-wrap: wrap;

  a {
    width: 44%;
    margin: hz-spacing(xxs);
  }
  @include hz-breakpoint-s-up {
    a {
      width: 45%;
    }
  }
  @include hz-breakpoint-m-up {
    a {
      width: 46%;
    }
  }
  @include hz-breakpoint-l-up {
    a {
      width: 30%;
    }
  }
}

.item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: hz-spacing(s);
  text-align: left;
}

.imageContainer {
  margin-right: hz-spacing(xs);

  .image {
    display: inline-block;
    height: 98px;
    object-fit: cover;
    width: 98px;
  }
}

.noImage {
  background-color: hz-color(background-light);
  height: 98px;
  margin-right: hz-spacing(xs);
  padding: hz-spacing(s);
  width: 98px;
}

.data {
  overflow: hidden;
  width: 100%;
}

.title {
  @include hz-textStyle(body-regular);
  color: hz-color(signal-link-default);
}

.price {
  @include hz-textStyle(body-large-strong);
  color: hz-color(text-primary);
  margin-top: hz-spacing(xxxxs);
}

.location {
  @include hz-textStyle(body-regular);
  color: hz-color(text-secondary);
  margin-top: hz-spacing(xxxxs);
}
