
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.buyersProtectionLink {
  display: flex !important;
  margin-top: hz-spacing(xs) !important;
  text-align: left;

  @include hz-breakpoint-m-up {
    align-items: center;
  }

  .label {
    margin-left: hz-spacing(xxxs);
  }
}
