
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.shippingModalLink {
  .shippingLink {
    align-items: baseline;
    display: flex !important;
    text-align: left;
    width: 100%;
  
    @include hz-breakpoint-m-up {
      align-items: center;
    }
  }
  
  .labelAndInfoIcon {
    align-items: baseline;
    display: flex;
    width: 100%;
  
    @include hz-breakpoint-m-up {
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .label {
    @include hz-breakpoint-m-up {
      width: 115px;
    }
  }

  .infoIcon {
    margin-top: hz-spacing(xs)
  }
}
