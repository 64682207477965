
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
@import '~@hz-design-system/design-tokens/dist/web/global/tokens/Breakpoints';

.root {
  flex: 0 0 100%;
  position: relative;

  @include hz-breakpoint-m-up {
    flex: 0 0 60%;
    overflow: hidden;
  }

  @include hz-breakpoint-l-up {
    flex: 0 0 400px;
    overflow: hidden;
  }

  &.showLargePhotos {
    flex: 0 0 100%;
  }
}

.carouselIndexContainer {
  bottom: hz-spacing(xs);
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);

  @include hz-breakpoint-m-up {
    top: 270px;
  }

  .showLargePhotos & {
    @include hz-breakpoint-m-up {
      top: 410px;
    }
  }

  .carouselIndex {
    background: rgba(0, 0, 0, 0.6);
    border-radius: hz-spacing(xxxs);
    color: hz-color(text-primary--inverse);
    padding: hz-spacing(xxxs) hz-spacing(xxs);
  }
}

.dialog {
  display: flex;
  flex-direction: column;
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.carouselIndexContainerForModal {
  bottom: hz-spacing(m);

  @include hz-breakpoint-m-up {
    bottom: auto;
    flex-basis: 100%;
    height: 0;
    position: relative;
    top: -104px;
  }
}

.dialogContent {
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 -#{hz-spacing(s)};
  max-height: 100%;
  min-height: 100%;
  overflow: hidden;

  @include hz-breakpoint-m-up {
    margin: 0;
    max-height: calc(100% - 114px); // 114px is a banner height + marginTop
    min-height: calc(100% - 114px);
    padding: 0 50px;
    z-index: 1;
  }
}

.dialogBanner {
  display: none;

  @include hz-breakpoint-m-up {
    display: block;
  }
}

.actions {
  padding: hz-spacing(xxs) hz-spacing(s) 0;

  @include hz-breakpoint-m-up {
    padding: hz-spacing(xxs) 0 0;
  }
}

.zoom {
  display: none;

  @include hz-breakpoint-m-up {
    display: inline-block;
  }
}

.videoLink {
  display: inline-block;
  margin-right: hz-spacing(s);
}

.video {
  border: 0;
  height: 100%;
  width: 100%;
}
