
      @import "~@ecg-marktplaats/marktplaats-design-sdk/lib/themes/twh/Variables.scss";

      @import '~@hz-design-system/design-tokens/dist/web/twh/tokens/Theme.scss';
      @import '~@hz-design-system/design-tokens/dist/web/global/ThemeGetters.scss';
    
.root {
  background-color: hz-color(background-light);
  display: flex;
  height: 70px;
  order: -1;
  padding: hz-spacing(xs) 0 hz-spacing(xs) hz-spacing(xxxs);
  width: 100%;

  &[data-collapsed='true'] {
    display: none;
  }
}

.blockLink {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-left: hz-spacing(s);
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  margin-bottom: hz-spacing(xxxs);

  &:global(.hz-Asset.hz-SvgAsset) {
    height: 20px;
  }

  &:global(.hz-Asset.hz-SvgAssetTenant--nlnl) {
    width: 134px;
  }

  &:global(.hz-Asset.hz-SvgAssetTenant--nlbe) {
    width: 88px;
  }

  &:global(.hz-Asset.hz-SvgAssetTenant--frbe) {
    width: 95px;
  }
}

.subtitile {
  @include hz-textStyle(body-regular-strong);
  color: hz-color('text-primary');
}

